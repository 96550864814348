import * as React from "react"
import QRRedirect from "../../components/qrRedirect"

//TODO - UTM
const QR_Summer2023Card = () => {
  return (
    <main>
      <title>Redirecting...</title>
      <QRRedirect source="summer-2023-card" destination="https://store.steampowered.com/app/1873200/Towers_of_Thana?utm_source=qr-summer-2023&utm_medium=qr-merch-card&utm_campaign=purchase" />
    </main>
  )
}

export default QR_Summer2023Card